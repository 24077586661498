import React from 'react';
import { Switch } from "react-router-dom";
import _ from 'lodash';
import PublicRoute from './PublicRoute';

import Main from '../pages/main';

export const getPublicRoute = [
  { path:'/:shopCode', component: Main, title: 'Main', exact: true },
];

function renderPublicRoutes() {
  return _.map(getPublicRoute, function(item) {
    if (item.exact) {
      return <PublicRoute key={item.path} path={item.path} exact component={item.component} title={item.title} />
    } else {
      return <PublicRoute key={item.path} path={item.path} component={item.component} title={item.title} />
    }
  });
}

function Routes() {
  return (
    <Switch>
      {renderPublicRoutes()}
    </Switch>
  );
}

export default Routes;
