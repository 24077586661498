import { blackListRoutes, defaultAccentsMap } from '../enum';

export const normalizePathname = (pathname) => {
  let accentsMap = {};
  for (let index = 0; index < defaultAccentsMap.length; index++){
    const letters = defaultAccentsMap[index].letters;
    for (let indexLetter = 0; indexLetter < letters.length ; indexLetter++){
      accentsMap[letters[indexLetter]] = defaultAccentsMap[index].base;
    }
  }

  function removeAccents(str) {
    // eslint-disable-next-line
    return str.replace(/[^\u0000-\u007E]/g, function(a){
      return accentsMap[a] || a;
    });
  }

  pathname = removeAccents(String(pathname));
  pathname = String(pathname).toLowerCase();
  pathname = pathname.replace(/[_\s]/g, '-');
  pathname = pathname.replace(/[^-a-z0-9]/g, '');
  pathname = pathname.replace(/-{2,}/g, '-');
  pathname = pathname.replace(/-$/g, '');
  return (pathname);
}

export const maskPhone = (number) => {
  let viewNumber = "";
  if (number) {
    number = String(number);
    if (number.length > 0) {
      const arrNumber = number.split('');
      viewNumber = "(__) _____-____";

      arrNumber.forEach((n) => {
        viewNumber = viewNumber.replace(/_/, n);
      });

      switch (arrNumber.length) {
        case 1:
          viewNumber = viewNumber.replace(/[) -]/g, '');
          break;
        case 2: case 3: case 4: case 5: case 6: case 7:
          viewNumber = viewNumber.replace(/[-]/g, '');
          break;
        default: break;
      }
    }
  }
  viewNumber = viewNumber.replace(/_/g, '');
  return viewNumber;
}

export const valideBlackList = (identifier, operator = '>', stateCurrent = false) => {
  if (identifier.length > 0) {
    if (customIndexOf(identifier, 'nextar', '>')) {
      return operator === '>' ? true : false;
    }
    return customIndexOf(blackListRoutes, identifier, operator);
  }
  return stateCurrent;
}

export const lettersInitial = (string) => {
  var words = string.split(' ');
  if (words.length > 1) {
    if (!words[1].substring(0,1)) {
      return words[0].substring(0,2);
    } else {
      return `${words[0].substring(0,1)}${words[1].substring(0,1)}`;
    }
  } else {
    return words[0].substring(0,2);
  }
}

export const toBase64 = file => new Promise((resolve, reject) => {
  var reader = new FileReader();
  reader.onload = (e) => {
    var img = document.createElement("img");
    img.onload = () => {
      var canvas = document.createElement('canvas');
      var ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0);

      var MAX_WIDTH = 500;
      var MAX_HEIGHT = 500;
      var width = img.width;
      var height = img.height;

      if (width > height) {
        if (width > MAX_WIDTH) {
          height *= MAX_WIDTH / width;
          width = MAX_WIDTH;
        }
      } else {
        if (height > MAX_HEIGHT) {
          width *= MAX_HEIGHT / height;
          height = MAX_HEIGHT;
        }
      }
      canvas.width = width;
      canvas.height = height;
      // eslint-disable-next-line
      var ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0, width, height);
      resolve(canvas.toDataURL(file.type));
    }
    img.src = e.target.result;
  }
  reader.onerror = error => reject(error);
  reader.readAsDataURL(file);
});

const customIndexOf = (range, searchValue, operator) => {
  switch (operator) {
    case '>': default:
      return (range.indexOf(searchValue) > -1);
    case '===':
      return (range.indexOf(searchValue) === -1);
  }
}
