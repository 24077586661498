const initialState = {
  preferences: {
    shopCode: null,
    identifier: '',
    publicAllProducts: false,
    publicCatalog: true,
    stockControl: false,
    logoBase64: null,
    logoHash: '',
    name: '',
    phone: null,
    address: {
      formattedAddress: '',
      street: '',
      number: null,
      complement: '',
      neighborhood: '',
      city: '',
      state: '',
      zipCode: '',
      latitude: 0,
      longitude: 0
    },
    locale: 'pt-br',
    country: 'BR',
    currencyConfig: {
      currencySymbol: 'R$',
      decimals: 2,
      groupSep: '.',
      decimalSep: ','
    },
    color: '#000'
  },
  errorForm: { step0: false, step1: false, step2: false, step3: false },
  found: null,
  availableIdentifier: null,
  loadPreferences: false,
  stepCurrently: 0,
  isSaving: false,
  logoUploaded: false
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'STEP_INCREMENT':
      return {
        ...state,
        stepCurrently: state.stepCurrently === 3 ? 3 : state.stepCurrently + 1
      }
    case 'STEP_DECREMENT':
      let newErrorForm = state.errorForm
      newErrorForm[`step${state.stepCurrently}`] = false

      return {
        ...state,
        errorForm: { ...state.errorForm, ...newErrorForm },
        stepCurrently: state.stepCurrently === 0 ? 0 : state.stepCurrently - 1
      }
    case 'SET_STEP':
      return {
        ...state,
        stepCurrently: action.payload
      }
    case 'SET_PREFERENCES':
      return {
        ...state,
        availableIdentifier:
          action.payload && action.payload.identifier === ''
            ? null
            : state.availableIdentifier,
        preferences: {
          ...state.preferences,
          ...action.payload,
          address: { ...state.preferences.address, ...action.payload.address }
        }
      }
    case 'SET_LOGO':
      return {
        ...state,
        preferences: { ...state.preferences, logoHash: action.payload }
      }
    case 'SET_LOGO_UPLOADED':
      return {
        ...state,
        logoUploaded: action.payload
      }
    case 'SET_ADDRESS':
      return {
        ...state,
        preferences: {
          ...state.preferences,
          address: {
            ...state.preferences.address,
            ...action.payload
          }
        }
      }
    case 'REMOVE_ADDRESS':
      return {
        ...state,
        preferences: {
          ...state.preferences,
          address: {
            ...initialState.preferences.address,
            complement: state.preferences.address.complement
          }
        }
      }
    case 'SET_FOUND':
      return {
        ...state,
        loadPreferences: true,
        found: action.payload
      }
    case 'SET_AVAILABLE_IDENTIFIER':
      return {
        ...state,
        availableIdentifier:
          state.preferences.identifier === '' ? null : action.payload
      }
    case 'SET_ERROR_FORM':
      return {
        ...state,
        errorForm: { ...state.errorForm, ...action.payload }
      }
    case 'RESET_ERROR_FORM':
      return {
        ...state,
        errorForm: initialState.errorForm
      }
    case 'SET_SAVING':
      return {
        ...state,
        isSaving: !state.isSaving
      }
    default:
      throw new Error()
  }
}

export { initialState, reducer }
