import React  from 'react';

export function Tab({ activeTab, disableTab, errorTab, index, label, onClickTab, className = '', style }) {
  className += ' tab-list-item';

  if(activeTab) className += ' tab-list-active';
  if(disableTab) className += ' disabled';

  return (
    <li
      className={className}
      onClick={() => !disableTab ? onClickTab(index) : null}
      style={style}
    >
      <div className={errorTab ? 'error' : ''}></div>
      {label}
      <hr />
    </li>
  );
}
