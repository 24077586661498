import React, { memo } from 'react';
import { Route, Redirect } from 'react-router-dom';

function PublicRoute({component: Component, restricted, ...rest}) {
  return (
    <Route {...rest} render={props => (
      false ?
        <Redirect to={'/'} />
      : <Component {...props} />
    )} />
  );
};

export default memo(PublicRoute);
