import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import AuthContainer from './AuthContainer';

function AppContainer() {

  return (
    <BrowserRouter>
      <AuthContainer />
    </BrowserRouter>
  );
}

export default AppContainer;
