import React from 'react';

export function Footer({ buttonAction, buttonBack }) {
  return (
    <footer>
      <div className="container">
        {buttonAction}
        {buttonBack}
      </div>
    </footer>
  );
}
