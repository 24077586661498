import React from 'react';
import { Close } from '../../assets/images';

export function Header({ children, isSaving }) {
  return (
    <header className={`${isSaving ? 'disabled' : ''}`}>
      <div className="container">
        <div className="content">
          { children }
        </div>
        <div className="buttonClose" onClick={() => !isSaving ? alert('@@closemodal') : null}>
          <Close className='icon' />
        </div>
      </div>
    </header>
  );
}
