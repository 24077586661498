import React from 'react';

import Storeinfo from '../../pages/storeinfo';
import Address from '../../pages/address';
import Website from '../../pages/website';
import Stockcontrol from '../../pages/stockcontrol';


export function SlideBody({ state, dispatch }) {
  const pages = [
    <Storeinfo key="Storeinfo" state={state} dispatch={dispatch} />,
    <Address key="Address" state={state} dispatch={dispatch} />,
    <Website key="Website" state={state} dispatch={dispatch} />,
    <Stockcontrol key="Stockcontrol" state={state} dispatch={dispatch} />,
  ];

  return (
    <div className="slidebody container">
      <div className={`listpages step${state.stepCurrently}`}>
        {pages.map((page) => {
          return (page);
        })}
      </div>
    </div>
  );
}
