import React from 'react';
import { postPreferences } from '../../../services';
import { Header, Footer, SlideBody } from '../../../components/pages';
import { valideBlackList } from '../../../utils';

export default function Save({state, dispatch}) {
  const isDisabled = ( state.errorForm.step0 || state.errorForm.step1 || state.errorForm.step2 ) ? ' disabled' : '';

  const hasErrorStoreinfo = (state.preferences.name.length === 0);
  const hasErrorAddress = (state.preferences.address.formattedAddress.length === 0);

  const verifyError = (callback) => {
    switch (state.stepCurrently) {
      case 0:
        if (hasErrorStoreinfo) {
          dispatch({type: 'SET_ERROR_FORM', payload: { step0: true }});
        } else {
          dispatch({type: 'SET_ERROR_FORM', payload: { step0: false }});
          callback();
        }
      break;
      case 1:
        if (hasErrorAddress) {
          dispatch({type: 'SET_ERROR_FORM', payload: { step1: true }});
        } else {
          dispatch({type: 'SET_ERROR_FORM', payload: { step1: false }});
          callback();
        }
      break;
      default:
        dispatch({type: 'RESET_ERROR_FORM'});
        callback();
      break;
    }
  }

  const savePreferences = () => {
    let hasErrorWebSite = (state.preferences.identifier.length === 0 && (state.availableIdentifier === false || state.availableIdentifier === null));
    hasErrorWebSite = valideBlackList(state.preferences.identifier, '>', hasErrorWebSite);

    if (hasErrorWebSite) {
      dispatch({type: 'SET_ERROR_FORM', payload: { step2: true }});
      return;
    } else {
      if (!hasErrorStoreinfo && !hasErrorAddress) {
        dispatch({type: 'RESET_ERROR_FORM'});

        const data = {
          preferences: [
            state.preferences
          ]
        };
        dispatch({type: 'SET_SAVING'});
        postPreferences(data).then((result) => {
          dispatch({type: 'SET_SAVING'});
          alert('@@savepreferences');
          console.log(result);
        }).catch((erro) => {
          dispatch({type: 'SET_SAVING'});
          alert('@@errorpreferences');
          console.log(erro);
        })
      }
    }
  };

  const renderButtonAction = () => {
    if (state.stepCurrently < 2)
      return (<button className={`buttonNext${isDisabled}`} onClick={() => verifyError(() => dispatch({type: 'STEP_INCREMENT'}))}>Avançar</button>);
    else
      return (<button className={`buttonSave${state.isSaving ? ' disabled' : isDisabled}`} onClick={() => !state.isSaving ? savePreferences() : null}>Ligar Catálogo</button>);
  }

  const renderButtonBack = () => {
    if (state.stepCurrently > 0)
      return (<button className={`buttonBack`} onClick={() => dispatch({type: 'STEP_DECREMENT'})}>Voltar</button>);
    else
      return null;
  }

  return (
    <div className="flow save">
      <Header isSaving={state.isSaving}>
        <span className="titlepage">
          Ligar Catálogo Online
        </span>
      </Header>
      <SlideBody state={state} dispatch={dispatch} />
      <Footer buttonAction={renderButtonAction()} buttonBack={renderButtonBack()} />
    </div>
  );
}
