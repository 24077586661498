import React from 'react';
import { postPreferences, availableIdentifier } from '../../../services';
import { Header, SlideBody, Footer, Tabs } from '../../../components/pages';
import { valideBlackList } from '../../../utils';
import { loadingIcon } from '../../../assets/images';

export default function Edit({ state, dispatch }) {
  const isDisabled = ( state.errorForm.step0 || state.errorForm.step1 || state.errorForm.step2 || state.isSaving ) ? ' disabled' : '';

  const hasErrorStoreinfo = (state.preferences.name.length === 0);
  const hasErrorAddress = (state.preferences.address.formattedAddress.length === 0);
  let hasErrorWebSite = (state.preferences.identifier.length === 0 && (state.availableIdentifier === false || state.availableIdentifier === null));
  hasErrorWebSite = valideBlackList(state.preferences.identifier, '>', hasErrorWebSite);

  const verifyError = (callback) => {
    if (hasErrorStoreinfo) {
      dispatch({type: 'SET_ERROR_FORM', payload: { step0: true }});
    }
    if (hasErrorAddress) {
      dispatch({type: 'SET_ERROR_FORM', payload: { step1: true }});
    }
    if (hasErrorWebSite) {
      dispatch({type: 'SET_ERROR_FORM', payload: { step2: true }});
    }

    if (!hasErrorStoreinfo && !hasErrorAddress && !hasErrorWebSite && !state.isSaving) {
      if (state.availableIdentifier === null) {
        availableIdentifier(state.preferences.shopCode, state.preferences.identifier)
        .then(({ data }) => {
          if (data.available ) {
            dispatch({type: 'SET_SAVING'});
            dispatch({ type: 'SET_AVAILABLE_IDENTIFIER', payload: data.available });
            dispatch({type: 'RESET_ERROR_FORM'});
            callback();
          } else {
            dispatch({type: 'SET_ERROR_FORM', payload: { step2: true }});
          }
        });
      } else {
        dispatch({type: 'SET_SAVING'});
        dispatch({type: 'RESET_ERROR_FORM'});
        callback();
      }
    }
  }

  const savePreferences = () => {
    const data = {
      preferences: [
        state.preferences
      ]
    };
    postPreferences(data).then((result) => {
      dispatch({type: 'SET_SAVING'});
      setTimeout(() => alert('@@savepreferences'), 100);
    }).catch((erro) => {
      dispatch({type: 'SET_SAVING'});
      console.log(erro);
      alert('@@errorpreferences');
    })
  };

  const renderButtonAction = () => {
    return (<button className={`buttonSave${isDisabled}`} onClick={() => verifyError(() => savePreferences())}>Salvar {state.isSaving ? <img src={loadingIcon} alt=""/> : ''}</button>);
  }

  return (
    <div className="flow edit">
      <Header isSaving={state.isSaving}>
        <Tabs state={state} dispatch={dispatch} />
      </Header>
      <SlideBody state={state} dispatch={dispatch} />
      <Footer buttonAction={renderButtonAction()} />
    </div>
  );
}
