import React, { useState } from 'react';
import { availableIdentifier } from '../../services';
import { normalizePathname, valideBlackList } from '../../utils';

export default function Website({ state, dispatch }) {
  const isError = state.errorForm.step2 ? 'error' : '';

  const handleDispatch = (data) => {
    dispatch({ type: 'SET_PREFERENCES', payload: data });
  }

  const [debounce, setDebounce] = useState(null);
  const [loadingAvailable, setLoadingAvailable] = useState(false);

  const dispatchAvailableIdentifier = (identifier) => {
    dispatch({ type: 'SET_AVAILABLE_IDENTIFIER', payload: null });
    if (identifier.length > 0) {
      setLoadingAvailable(true);
      if (valideBlackList(identifier, '===')) {
        availableIdentifier(state.preferences.shopCode, identifier).then(({ data }) => {
          setLoadingAvailable(false);
          dispatch({ type: 'SET_AVAILABLE_IDENTIFIER', payload: data.available });

          if (data.available) {
            dispatch({type: 'SET_ERROR_FORM', payload: { step2: false }});
          } else {
            dispatch({type: 'SET_ERROR_FORM', payload: { step2: true }});
          }
        })
      } else {
        setLoadingAvailable(false);
        dispatch({ type: 'SET_AVAILABLE_IDENTIFIER', payload: false });
        dispatch({type: 'SET_ERROR_FORM', payload: { step2: true }});

      }
    } else {
      dispatch({type: 'SET_ERROR_FORM', payload: { step2: true }});
    }
  }

  const changeIdentifier = (event) => {
    const identifier = normalizePathname(event.currentTarget.value);
    handleDispatch({ identifier });

    if (debounce) {
      clearTimeout(debounce);
      setDebounce(null);
    }

    setDebounce(setTimeout(() => {dispatchAvailableIdentifier(identifier)},500));
  }

  const changePublicAll = (event) => {
    handleDispatch({ publicAllProducts: event.currentTarget.checked });
  }

  const verifyAvailableIdentifier = () => {
    if (state.availableIdentifier != null) {
      return !state.availableIdentifier ? ' unavailable' : ' available';
    } else {
      if (isError && !loadingAvailable)
        return ' unavailable';
      else
        return '';
    }
  }

  const renderLoadingPulse = () => {
    return (
      <div className="ring-container">
        <div className="ringring"></div>
        <div className="circle"></div>
      </div>
    );
  }

  return (
    <div id="website" className={isError}>
      <div className="container">
        <span className="titlestep"><span className="step">3/3</span>Website do Catálogo</span>
        <div className={`containerIdentifier${verifyAvailableIdentifier()}`}>
          <input
            id="identifier"
            type="text"
            maxLength={100}
            onChange={changeIdentifier}
            value={normalizePathname(state.preferences.identifier)}
            placeholder="digite o nome do negócio"
            autoComplete="off"
          />
          <span className="label">www.meucomercio.com.br/</span>
          {loadingAvailable ? renderLoadingPulse() : null}
          <div className="feedback">
            <div className="dot"></div>
            <span className="msg">
              {state.availableIdentifier ? 'URL disponível' : 'URL indisponível' }
            </span>
          </div>
        </div>

        <div className="checkbox">
          <input id="publicAll" type="checkbox" onChange={changePublicAll} defaultChecked={state.preferences.publicAllProducts} />
          <label htmlFor="publicAll" >
            Publicar todos os produtos
          </label>
        </div>
      </div>
    </div>
  );
}
