// import React, { useState } from 'react';
import React, { useEffect } from 'react'
import { baseURL_LOGO } from '../../config'
import { InputMsgError } from '../../components/common'
import { maskPhone, lettersInitial, toBase64 } from '../../utils'

export default function Storeinfo({ state, dispatch }) {
  const isError = state.errorForm.step0 ? 'error' : ''

  var logo = state.logoUploaded
    ? `${baseURL_LOGO}/${state.preferences.logoHash}`
    : state.preferences.logoHash

  const onDrop = async picture => {
    const maxFileSize = 5242880

    if (picture.size < maxFileSize) {
      const result = await toBase64(picture).catch(e => Error(e))
      if (result instanceof Error) {
        console.log('Error: ', result.message)
        return
      }
      handleDispatch({ logoBase64: result })
      setLogo(result)
    } else {
      console.log('Imagem maior que 5 Mb.')
      return
    }
  }

  const setLogo = newLogo => {
    logo = newLogo
    dispatch({ type: 'SET_LOGO', payload: newLogo })
    dispatch({ type: 'SET_LOGO_UPLOADED', payload: false })
  }

  const handleLogo = () => {
    var input = document.querySelector('#uploadLogo')
    if (!logo) {
      input.click()
    } else {
      input.value = ''
      setLogo(null)
      handleDispatch({ logoBase64: null })
    }
  }

  const handleDispatch = data => {
    dispatch({ type: 'SET_PREFERENCES', payload: data })
  }

  const changeName = event => {
    const name = String(event.currentTarget.value).trimLeft()
    handleDispatch({ name })

    if (name.length > 0) {
      dispatch({ type: 'SET_ERROR_FORM', payload: { step0: false } })
    } else {
      dispatch({ type: 'SET_ERROR_FORM', payload: { step0: true } })
    }
  }

  const changePhone = event => {
    let phone = event.currentTarget.value.replace(/(\D)/g, '')
    if (event.currentTarget.value && event.currentTarget.value.length > 0) {
      if (
        event.currentTarget.value.length <
        maskPhone(state.preferences.phone).length
      ) {
        if (Number(phone) === state.preferences.phone) {
          phone = phone.substring(0, phone.length - 1)
        }
      }
    }
    handleDispatch({ whatsapp: Number(phone) })
  }


  const setWhatsapp = () => {
    if (state.preferences.phone !== null) {
      if (!state.preferences?.whatsapp) {
        handleDispatch({ whatsapp: Number(state.preferences.phone) })
      }
    }
  }

  useEffect(() => {
    setWhatsapp();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.preferences.phone]);
  return (
    <div id='storeinfo' className={isError}>
      <div className='container'>
        <span className='titlestep'>
          <span className='step'>1/4</span>Dados do Negócio
        </span>
        <div className='content'>
          <div className='inputText'>
            <label htmlFor='name'>Nome da Loja</label>
            <div className='containerInput'>
              <input
                id='name'
                type='text'
                autoComplete='off'
                maxLength={100}
                onChange={changeName}
                value={state.preferences.name}
              />
              {isError ? (
                <InputMsgError
                  msg={'Informe o nome da sua loja.'}
                  top={35}
                  left={5}
                />
              ) : null}
            </div>
            <label htmlFor='phone'>WhatsApp para Contato</label>
            <input
              id='phone'
              type='text'
              autoComplete='off'
              placeholder={'(DDD) 9 1234-5678'}
              onChange={changePhone}
              value={maskPhone(state.preferences.whatsapp || state.preferences.phone)}
            />
          </div>
          <div className='inputFile' onClick={() => handleLogo()}>
            <div
              className={`previewLogo ${logo ? 'hasLogo' : ''} ${!logo && state.preferences.name ? 'hasName' : ''
                }`}
            >
              {!logo && !state.preferences.name ? 'SEU LOGO' : ''}
              {!logo && state.preferences.name
                ? lettersInitial(state.preferences.name)
                : ''}
              {logo ? <img className='logo' src={logo} alt='' /> : null}
            </div>
            <input
              id='uploadLogo'
              type='file'
              accept='image/x-png,image/gif,image/jpeg,image/jpg'
              onChange={e => onDrop(e.currentTarget.files[0])}
              style={{ display: 'none' }}
            />
            <div
              className={`button ${logo ? 'remove' : ''}`}
              style={{ textAlign: 'center' }}
            >
              {logo ? <span>Remover imagem</span> : <span>Enviar arquivo</span>}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
