import React, { useReducer, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { LoadingSpinner } from '../../components/common';
import { Header } from '../../components/pages';
import { initialState, reducer } from '../../reducer';
import { getPreferences } from '../../services';

import Save from './flow/save';
import Edit from './flow/edit';

function Main() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { found, loadPreferences } = state;

  const { shopCode } = useParams();

  const init = useCallback(() => {
    getPreferences(shopCode).then(result => {
      let { found, data } = result.data;

      if (found && !data.hasOwnProperty('name') && !data.hasOwnProperty('identifier') && !data.hasOwnProperty('address')) {
        found = false;
      }

      dispatch({ type: 'SET_FOUND', payload: found });
      if (found) {
        dispatch({type: 'SET_PREFERENCES', payload: data });
        if (data.logoHash) {
          dispatch({type: 'SET_LOGO_UPLOADED', payload: true });
        }
      } else {
        dispatch({type: 'SET_PREFERENCES', payload: { shopCode: Number(shopCode) }});
      }
    })
  }, [dispatch, shopCode]);

  useEffect(() => {
    if (!loadPreferences) {
      init();
    }
  }, [init, loadPreferences]);

  const renderLoading = () => {
    return (
      <div style={{ position: 'relative', height: '100vh', width: '100vw' }}>
        <Header>
          <span className="titlepage">Catálogo Online</span>
        </Header>
        <LoadingSpinner />
      </div>
    );
  }

  if (found != null)
    return !found ? <Save state={state} dispatch={dispatch} /> : <Edit state={state} dispatch={dispatch} />;
  else
    return renderLoading();
}

export default Main;
