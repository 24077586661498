import axios from 'axios';
import { baseURL_API } from '../config';

//Preferences Store
const axiosPreferencesAPI = axios.create({
  baseURL: baseURL_API,
  timeout: 60000,
});

export const postPreferences = (data) => axiosPreferencesAPI({
  method: 'post',
  data,
  url: '/sync/preferences',
  headers: { 'Content-Type': 'application/json' }
});

export const getPreferences = (shopCode) => axiosPreferencesAPI({
  method: 'get',
  url: `/sync/preferences/${shopCode}`
});

export const availableIdentifier = (shopCode, identifier) => axiosPreferencesAPI({
  method: 'get',
  url: `/sync/preferences/${shopCode}/${identifier}`
});
