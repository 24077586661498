import React from 'react';
import ReactDOM from 'react-dom';
import AppContainer from './pages/AppContainer';

import './styles/index.scss';

ReactDOM.render(
  <React.StrictMode>
    <AppContainer/>
  </React.StrictMode>,
  document.getElementById('page')
);
