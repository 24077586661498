import React from 'react';
import { Tab } from '../common';

export function Tabs({ state, dispatch }) {
  const isActive = (step) => (state.stepCurrently === step);

  const tabs = [
    { label: 'Dados do Negócio', active: isActive(0), error: state.errorForm.step0 },
    { label: 'Endereço', active: isActive(1), error: state.errorForm.step1 },
    { label: 'Website', active: isActive(2), error: state.errorForm.step2 },
    { label: 'Estoque', active: isActive(3), error: state.errorForm.step3 },
  ];

  const handleStep = (step) => {
    dispatch({type: 'SET_STEP', payload: step});
  }

  return (
    <ol className="tab-list">
      {tabs.map((child, index) => {
        return (
          <Tab
            activeTab={child.active || false}
            disableTab={child.disable || false}
            errorTab={child.error || false}
            key={child.label}
            index={index}
            label={child.label}
            onClickTab={() => handleStep(index)}
            className={child.classNameTab}
            style={{...child.styleTab }}
          />
        );
      })}
    </ol>
  );
}
