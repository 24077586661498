import React from 'react';

export default function Stockcontrol({ state, dispatch }) {

  const handleDispatch = (data) => {
    dispatch({ type: 'SET_PREFERENCES', payload: data });
  }

  const changeStockControl = (event) => {
    switch (event.currentTarget.id) {
      case 'hiddenProducts':
        handleDispatch({ stockControl: true/*, showProductWithoutStock: false*/ });
      break;
      // case 'showProducts':
      //   handleDispatch({ stockControl: true, showProductWithoutStock: true });
      // break;
      default:
        handleDispatch({ stockControl: false/*, showProductWithoutStock: true*/ });
      break;
    }
  };

  return (
    <div id="stockcontrol">
      <div className="container">
        <span className="titlestep"><span className="step">4/4</span>Controle de Estoque</span>
        <div className="content">
          <div className="radiobox">
            <div>
              <input id="default" name="stockcontrol" type="radio" onChange={changeStockControl} checked={!state.preferences.stockControl} />
              <label htmlFor="default" >
                Manter item publicado, mesmo sem estoque
              </label>
            </div>
            <div>
              <input id="hiddenProducts" name="stockcontrol" type="radio" onChange={changeStockControl} checked={state.preferences.stockControl/* && !state.preferences.showProductWithoutStock*/} />
              <label htmlFor="hiddenProducts" >
                Despublicar produtos automaticamente ao zerar estoque
              </label>
            </div>
            {/* <div>
              <input id="showProducts" name="stockcontrol" type="radio" onChange={changeStockControl} checked={state.preferences.stockControl && state.preferences.showProductWithoutStock} />
              <label htmlFor="showProducts" >
                Informar itens sem estoque no catálogo
              </label>
            </div> */}
          </div>
          {/* {renderCard()} */}
        </div>
      </div>
    </div>
  );
}
