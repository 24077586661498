import React, { useState } from 'react';
import Script from 'react-load-script';
import _ from 'lodash';
import { InputMsgError } from '../../components/common';
import { URL_Google_Place_AutoComplete } from '../../config';
import { Close } from '../../assets/images';
// import { newMap, setMarkerMap } from '../../utils';

export default function Address({ state, dispatch }) {
  const isError = state.errorForm.step1 ? 'error' : '';
  const [isAddressSelected, setIsAddressSelected] = useState(!!state.preferences.address.formattedAddress);

  // Carrega a posição no mapa quando a loja já tem endereço cadastrado
  // if (isAddressSelected || !!state.preferences.address.formattedAddress) {
  //   if(
  //     typeof google !== 'undefined' &&
  //     state.preferences.address.latitude !== 0 &&
  //     state.preferences.address.longitude !== 0 &&
  //     window.map.zoom === 4
  //   ) {
  //     setMarkerMap({lat: state.preferences.address.latitude, lng: state.preferences.address.longitude});
  //   }
  // }

  const handleDispatch = (data) => {
    dispatch({ type: 'SET_ADDRESS', payload: data });
  }

  const changeComplement = (event) => {
    handleDispatch({ complement: String(event.currentTarget.value).trimLeft() });
  }

  const handleScriptLoad = () => {
    // Inicializa o Mapa
    // newMap(google);

    // O comentário abaixo é necessário para o ESLINT.
    /*global google*/
    window.autocomplete = new google.maps.places.Autocomplete(
      document.getElementById('autocomplete'),
      { },
    );
    window.autocomplete.setComponentRestrictions({'country': ['br']});
    window.autocomplete.setFields(['address_components', 'formatted_address', 'geometry']);
    window.autocomplete.addListener('place_changed', handlePlaceSelect);
  }

  const handlePlaceSelect = () => {
    const addressObject = window.autocomplete.getPlace();
    const { address_components, formatted_address, geometry } = addressObject;

    // Configura a posição no mapa de acordo com o que foi selecionado.
    // setMarkerMap(geometry.location);

    const getValue = (field) => {
      const result = _.filter(address_components, function(item) { return item.types.indexOf(field) > -1; });
      if (result.length > 0) {
        return result[0].short_name || '';
      } else {
        return '';
      }
    }

    if (address_components) {
      const address = {
        formattedAddress: formatted_address,
        street: getValue("route"),
        number: getValue("street_number"),
        neighborhood: getValue("sublocality_level_1"),
        city: getValue("administrative_area_level_2"),
        state: getValue("administrative_area_level_1"),
        country: getValue("country"),
        zipCode: getValue("postal_code"),
        latitude: geometry.location.lat(),
        longitude: geometry.location.lng(),
      };
      document.querySelector('#autocomplete').value = '';
      setIsAddressSelected(true);
      handleDispatch(address);

      if (address.formattedAddress.length > 0) {
        dispatch({type: 'SET_ERROR_FORM', payload: { step1: false }});
      } else {
        dispatch({type: 'SET_ERROR_FORM', payload: { step1: true }});
      }
    }
  }

  const handleRemoveAddressSelected = () => {
    dispatch({ type: 'REMOVE_ADDRESS' });
    document.querySelector('#autocomplete').value = '';
    setIsAddressSelected(false);
    dispatch({type: 'SET_ERROR_FORM', payload: { step1: true }});

    // Reseta a posição no mapa.
    // newMap(google);
  }

  const handleAddressFocusOut = (event) => {
    event.currentTarget.value = '';
    document.querySelectorAll('.pac-item').forEach((item) => item.remove());
    if (event.currentTarget.value.length === 0 && state.preferences.address.formattedAddress.length === 0)
      dispatch({type: 'SET_ERROR_FORM', payload: { step1: true }});
    else
      dispatch({type: 'SET_ERROR_FORM', payload: { step1: false }});
  }

  const renderAddressSelected = () => {
    return (
      <div
        className="container-address-selected"
        title={state.preferences.address.formattedAddress}
        onClick={() => handleRemoveAddressSelected()}
      >
        <div className="content">
          <span>{state.preferences.address.formattedAddress}</span>
          <Close className="icon-close" />
        </div>
      </div>
    );
  }

  return (
    <>
      <Script url={URL_Google_Place_AutoComplete}
        onLoad={() => handleScriptLoad()}
      />
      <div id="address" className={isError}>
        {/* Tag onde o mapa será carregado. */}
        {/* <div id="map" style={{height: '100%'}}></div> */}
        <div className="container">
          <span className="titlestep"><span className="step">2/3</span>Endereço do Negócio</span>
          <label htmlFor="autocomplete">Informe o Endereço e número</label>
          <div className="containerInput">
            <input
              id="autocomplete"
              type="text"
              placeholder="Rua exemplo, 123"
              maxLength={200}
              onBlur={handleAddressFocusOut}
              autoComplete="off"
            />
            <div className="container-icon icon-search"></div>
            {isError ? <InputMsgError msg={'Pesquise o endereço da sua loja e selecione na lista.'} top={35} left={5} /> : null}
            {isAddressSelected || !!state.preferences.address.formattedAddress ? renderAddressSelected() : null}
          </div>
          <label htmlFor="complement">Complemento</label>
          <input
            id="complement"
            type="text"
            onChange={changeComplement}
            defaultValue={state.preferences.address.complement}
            autoComplete="off"
            maxLength={100}
          />
        </div>
      </div>
    </>
  );
}
