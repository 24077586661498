import React  from 'react';

export function InputMsgError({ msg, top = 0, left = 0 }) {
  return (
    <div className="container-error" style={{ top: `${top}px`, left: `${left}px` }}>
      <div className="dot"></div>
      <span className="msg">
        { msg || '' }
      </span>
    </div>
  );
}
